'use strict'
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.BoxSlideShow': deleteChildlessComponent,
    'wysiwyg.viewer.components.StripContainerSlideShow': deleteChildlessComponent,
    'wysiwyg.viewer.components.Repeater': deleteChildlessComponent
}

function deleteChildlessComponent(pageJson, cache, mobileView, comp, idGenerator, parentCompsArray) {
    const children = comp.components || []

    if (!children.length) {
        parentCompsArray.splice(parentCompsArray.indexOf(comp), 1)
    }
}

module.exports = {
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}